import { Injectable, SecurityContext } from '@angular/core';
import {
  HttpClient,
  HttpParams,
  HttpErrorResponse,
} from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from '@environment';
import {
  emitIframeMessage,
  IframeMessage,
} from '@longnecktech/splash-commons-fe';
import { overridePrizeLabel } from '@shared/utils/prize-label.utils';
import { injectCSSVariablesIntoDOM } from '@shared/utils/theme.utils';
import { SessionService } from './session.service';
import { Game } from '@shared/types/game';
import {
  tap,
  Observable,
  catchError,
  BehaviorSubject,
  take,
  throwError,
  map,
  of,
  mergeMap,
} from 'rxjs';
import {
  Page,
  UserAction,
  QuestionType,
  Question,
  Transaction,
  TransactionLine,
  LinePick,
} from '@longnecktech/splash-commons-fe';
import { CurrencyService } from './currency.service';
import { QuizPrizePackage } from '@shared/types/prize-package';
import { PicksRequest } from '@shared/types/submission-resource';
import { NotificationService } from '@services/notification.service';
import { Leaderboard } from '@shared/types/leaderboard';
import { QuestionOption } from '@longnecktech/splash-commons-fe';
import {
  DeepLinkingRequest,
  DeepLinkingResponse,
} from '@shared/types/deeplinking';
import { UserActionService } from './user-action.service';

@Injectable({
  providedIn: 'root',
})
export class GameService {
  private _isLoading = new BehaviorSubject<boolean>(false);
  private _transaction = new BehaviorSubject<Page<Transaction> | undefined>(
    undefined,
  );

  isLoading$ = this._isLoading.asObservable();
  transaction$ = this._transaction.asObservable();
  currentTransactionGame$ = this.transaction$.pipe(
    map((transaction) => transaction?.content?.[0]?.quizGame),
  );

  constructor(
    private http: HttpClient,
    private session: SessionService,
    private currencyService: CurrencyService,
    private notificationService: NotificationService,
    private userActionService: UserActionService,
    private sanitizer: DomSanitizer,
  ) {}

  fetchGame(): Observable<Game> {
    return this.http
      .get<Game>(
        `${environment.backendUrl}/api/quiz/game?${this.getQueryParams()}`,
      )
      .pipe(
        tap((data: Game) => {
          this.session.setCurrentGame({
            ...data,
            leaderboard: this.getLeaderboard(data.leaderboard),
            prizePackage: this.getUpdatedPackage(data.prizePackage),
            questions: data.questions
              .map((question) => {
                if (question.type === QuestionType.CorrectScore) {
                  return {
                    ...question,
                    competitor1Score: 0,
                    competitor2Score: 0,
                  };
                }
                return question;
              })
              .sort((a, b) => a.index - b.index),
          });
          injectCSSVariablesIntoDOM(data.theme);
        }),
      );
  }

  fetchTransactions(page: number) {
    let params = new HttpParams();
    params = params.append('page', page.toString());
    return this.http
      .get<Page<Transaction>>(
        `${environment.backendUrl}/api/quiz/transaction/history`,
        {
          params,
        },
      )
      .pipe(
        tap((data) => {
          if (data.content.length > 0) {
            this.session.updateHasResult(true);
          }
        }),
        map((value) => {
          if (value.empty) return value;
          value.content?.[0]?.quizLines.forEach(
            (ql) =>
              (ql.prize = ql.prize
                ? {
                    ...ql.prize,
                    label: overridePrizeLabel(ql.prize, this.currencyService),
                  }
                : undefined),
          );
          value.content?.[0]?.quizLines.sort(
            (a: { lineIndex: number }, b: { lineIndex: number }) =>
              a.lineIndex - b.lineIndex,
          );
          value.content?.[0]?.quizLines.forEach((l: TransactionLine) =>
            l.picks.forEach(
              (p: LinePick) =>
                (p.option = this.getOption(value.content?.[0]?.quizGame, p)),
            ),
          );
          value.content?.[0]?.quizLines.forEach((l: TransactionLine) =>
            l.picks.sort(
              (a: LinePick, b: LinePick) =>
                (a.option?.index || 0) - (b.option?.index || 0),
            ),
          );
          // add correct score value from quiz lines to quiz game
          // so that we can display it in My Picks section
          const allPicks = value.content?.[0]?.quizLines.reduce((acc, line) => {
            return [...acc, ...line.picks];
          }, [] as LinePick[]);
          value.content?.[0].quizGame.questions.forEach(
            (question: Question) => {
              if (question.type === QuestionType.CorrectScore) {
                const questionPick = allPicks.find(
                  (pick) => pick.questionUuid === question.uuid,
                );
                question.points = questionPick?.points ?? 0;
                question.competitor1Score = questionPick?.competitor1Score ?? 0;
                question.competitor2Score = questionPick?.competitor2Score ?? 0;
              }
            },
          );
          return { ...value };
        }),
        tap((value) => this._transaction.next(value)),
      );
  }

  submitPicks(questions?: Question[]): Observable<void | null> {
    if (!questions) return of(null);
    this._isLoading.next(true);
    const picks = this.getPicks(questions);
    return this.http
      .post<void>(
        environment.backendUrl +
          `/api/quiz/pick/v2/${this.session.game()?.uuid}`,
        picks,
      )
      .pipe(
        tap(() => {
          this.session.updateDataOnSubmittingPicks();
          this._isLoading.next(false);
        }),
        catchError((err: HttpErrorResponse) => this.handleError(err)),
      );
  }

  updatePicks(
    transaction: Transaction,
    questions?: Question[],
  ): Observable<void | null> {
    if (!questions) return of(null);
    this._isLoading.next(true);
    const picks = this.getPicks(questions);
    return this.http
      .put<void>(
        environment.backendUrl + `/api/quiz/pick/v2/${transaction.uuid}`,
        picks,
      )
      .pipe(
        tap(() => {
          this.session.updateDataOnSubmittingPicks();
          this._isLoading.next(false);
        }),
        catchError((err: HttpErrorResponse) => this.handleError(err)),
      );
  }

  sendBets(
    request: DeepLinkingRequest,
    gameUuid: string,
  ): Observable<DeepLinkingResponse> {
    this._isLoading.next(true);
    this.userActionService
      .sendAction(UserAction.CLICK_DEEPLINKING, {
        bets: request.Bets,
      })
      .pipe(take(1))
      .subscribe();
    return this.http
      .post<DeepLinkingResponse>(
        environment.backendUrl + '/api/quiz/deeplinking/' + gameUuid,
        request,
      )
      .pipe(
        tap(() => this._isLoading.next(false)),
        tap((res) => {
          if (res?.code) {
            emitIframeMessage(IframeMessage.Deeplinking, { code: res.code });
          }

          if (res?.redirectUrl) {
            const link = this.sanitizer.sanitize(
              SecurityContext.URL,
              res.redirectUrl,
            );
            if (link) {
              (window.top || window).location.href = link;
            }
          }

          if (res?.altenarOddsPayload?.oddIds) {
            emitIframeMessage(IframeMessage.Deeplinking, {
              oddIds: res.altenarOddsPayload.oddIds,
              type: 'splash_add_selection',
            });
          }

          if (res?.altenarEventsPayload?.eventId) {
            emitIframeMessage(IframeMessage.Deeplinking, {
              eventId: res.altenarEventsPayload.eventId,
              type: 'splash_redirect_to_event',
            });
          }
        }),
        catchError((err) => {
          this._isLoading.next(false);
          this.notificationService.showNotification('deeplink.failed');
          return throwError(() => new Error(err.message));
        }),
      );
  }

  getLinesForGame(gameUuid: string) {
    return this.http
      .get<Transaction | null>(
        environment.backendUrl + `/api/quiz/game/${gameUuid}/transaction`,
      )
      .pipe(
        mergeMap((transaction) => {
          if (transaction) {
            this.session.transaction.set(transaction);
            if (transaction.quizLines.length) {
              this.session.updateCurrentGameResult(true);
              this.session.removeGameFromStorage();
            }
            return of(transaction);
          } else {
            return throwError(
              () => new Error('No transaction found, this is ok.'),
            );
          }
        }),
      );
  }

  private handleError(err: HttpErrorResponse): Observable<never> {
    const message =
      err.status === 400 && err.error.message
        ? err.error.message
        : 'Error while submitting picks';
    this.notificationService.showNotification(message);
    this._isLoading.next(false);
    this.userActionService
      .sendAction(UserAction.TRANSACTION_ERROR, {
        when: 'submitting picks',
        error: message,
      })
      .pipe(take(1))
      .subscribe();
    return throwError(() => new Error(err.message));
  }

  private getLeaderboard(leaderboard: Leaderboard | null): Leaderboard | null {
    if (!leaderboard) return null;
    return {
      ...leaderboard,
      prizePackage: {
        ...leaderboard.prizePackage,
        prizes: leaderboard.prizePackage.prizes
          .map((prize) => ({
            ...prize,
            prize: {
              ...prize.prize,
              label: overridePrizeLabel(prize.prize, this.currencyService),
            },
          }))
          .slice()
          .sort((a, b) => a.score - b.score),
      },
    };
  }

  private getUpdatedPackage(prizePackage: QuizPrizePackage): QuizPrizePackage {
    if (prizePackage.prizes) {
      const updatedPrizes = prizePackage.prizes.map((prize) => {
        if (typeof prize.prize.value === 'number' && prize.prize.currency) {
          return {
            ...prize,
            prize: {
              ...prize.prize,
              label: overridePrizeLabel(prize.prize, this.currencyService),
            },
          };
        } else {
          return prize;
        }
      });

      return {
        ...prizePackage,
        prizes: updatedPrizes.sort((a, b) => b.score - a.score),
      };
    }

    return prizePackage;
  }

  private getPicks(questions: Question[]): PicksRequest {
    return questions.reduce((acc, question) => {
      if (question.type === QuestionType.Options) {
        const optionUuids = question.options
          .filter((option) => option.picked)
          .map((option) => option.uuid);
        if (optionUuids.length) {
          acc.push({
            questionUuid: question.uuid,
            optionUuids,
          });
        }
      }

      if (question.type === QuestionType.CorrectScore) {
        acc.push({
          questionUuid: question.uuid,
          competitor1Score: question.competitor1Score,
          competitor2Score: question.competitor2Score,
        });
      }

      if (question.type === QuestionType.NumberSelect) {
        acc.push({
          questionUuid: question.uuid,
          numberSelectValue: question.correctAnswer,
        });
      }

      return acc;
    }, [] as PicksRequest);
  }

  private getQueryParams(): string {
    const params: { instance?: string; gameUuid?: string } = {};
    if (this.session.instance) params['instance'] = this.session.instance;
    if (this.session.gameUuid) params['gameUuid'] = this.session.gameUuid;
    return new URLSearchParams(params).toString();
  }

  private getOption(game: Game, pick: LinePick): QuestionOption | undefined {
    const question = game.questions.find(
      (question) => question.uuid === pick.questionUuid,
    );
    if (!question || question.type === QuestionType.CorrectScore) return;
    return question.options.find((option) => option.uuid === pick.pickedUuid)!;
  }
}
