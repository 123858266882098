import { CanActivateFn, Router, ActivatedRouteSnapshot } from '@angular/router';
import { inject } from '@angular/core';
import { SessionService } from '@services/session.service';
import { GameService } from '@services/game.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { firstValueFrom, catchError, of } from 'rxjs';
import { WindowWithUser } from '@longnecktech/splash-commons-fe';

export async function executeTokenLogin(
  sessionService: SessionService,
  gameService: GameService,
) {
  sessionService.user.set((window as WindowWithUser).user);
  const authenticated = !!sessionService.user();
  if (authenticated) {
    const game = await firstValueFrom(gameService.fetchGame());
    await firstValueFrom(
      gameService.getLinesForGame(game.uuid).pipe(catchError(() => of(null))),
    );
  }
  return authenticated;
}

export const loggedInAuthGuard: CanActivateFn = async (
  route: ActivatedRouteSnapshot,
) => {
  const session = inject(SessionService);
  const gameService = inject(GameService);
  const router = inject(Router);
  const breakpointObserver = inject(BreakpointObserver);
  let authenticated = !!session.user();
  if (!authenticated) {
    if (session.thirdPartyToken) {
      authenticated = await executeTokenLogin(session, gameService);

      const isMobile = breakpointObserver.isMatched([Breakpoints.Handset]);
      if (authenticated && session.getCurrentGameResult()) {
        return router.navigate(isMobile ? ['/m/results'] : ['/d/results'], {
          queryParams: route.queryParams,
        });
      }
    }
  }
  return authenticated ? true : inject(Router).createUrlTree(['/error']);
};
